import { getUserUUIDFromCookie } from "@/utils/userUtils";
import UKToast from "@/components/UKToast";
import { jdiColorFetch } from "./colorFetchV2";
import { UserReviewedDetailResponse, ReviewDetailItem, Response } from "./service.interface";
// --------------- product 商详 ---------------
type FollowReqParams = {
    bizIds: string,
    bizType?: string
}

export type CartParams = {
    refer?: number,
    cartuuid: string,
    num: number,
    skuId: string
}

type CommnentReqParams = {
    skuId: string,
    type?: number,
    currentPage?: number,
    pageSize?: number,
}

type HelpfulReqParams = {
    commentId: string,
    operation: number
}

interface ReportCommentReq {
    commentId: string,
    targetReplyId: string | number,
    parentReplyId?: string | number,
    reportContent: string,
    reportReason: number,
    toPin: string,
    toContent: string

}

interface PublishReplyCommentReq {
    commentId: string,
    targetReplyId?: string | number,
    parentReplyId?: string | number,
    content: string,
    anonymousFlag?: boolean
}

interface CommentDetailReq {
    commentId: string;
    parentReplyId: string;
    skuId: string;
    currentPage?: number;
    pageSize?: number;
}
// 关注： https://cf.jd.com/pages/viewpage.action?pageId=553603015
export const followApi = async ({ bizIds, bizType = '1' }: FollowReqParams) => {
    // se_wishList_follow bizIds， bizType 1商品、2店铺
    const res = await jdiColorFetch(
        `user_wishlist_follow`, {
        method: 'post',
        bodyQuery: {
            bizIds: [bizIds],
            bizType
        }
    }
    );
    return res
}

// 取消关注： https://cf.jd.com/pages/viewpage.action?pageId=553603015
export const unFollowApi = async ({ bizIds, bizType = '1' }: FollowReqParams) => {
    // se_wishList_unFollow
    const res = await jdiColorFetch(
        `user_wishlist_cancel`, {
        method: 'post',
        bodyQuery: {
            bizIds: [bizIds],
            bizType
        }
    }
    );
    return res
}

export async function queryProductRecommendData(subSceneCode?: string) {
    const res = await jdiColorFetch(
        `guide_recommendation_get`,
        // cache保存时间5秒
        {
            bodyQuery: {
                sceneCode: 'product_detail_page',
                subSceneCode: subSceneCode || "",
            },
            next: { revalidate: 5 }, 
            method: 'post'
        }
    );
    if (res.error) {
        throw new Error(res.error)
    }
    return res;
}


/**
 * 普通商卡加入购物车
 * @param productList - [{ skuId: 88, num: "1" }] 加入购物车所需的参数
 * @param refreshCb -  加入购物车成功后刷新头部购物车数量
 */

export const addToCart = async (productList: any[], refreshCb?: () => void) => {
    const formatProductList = productList.map((item) => {
        item.itemType = 1;
        return item;
    });
    const bodyParam: BodyParam = {
        verticalTag: "cn_ybxt_b2c",
        operations: { products: formatProductList },
        cartuuid: getUserUUIDFromCookie(),
        userActionId: "addCart",
    };

    const result = await jdiColorFetch(
        `trade_cart_addItem`, {
        method: 'post',
        bodyQuery: {
            ...bodyParam
        }
    }
    );

    if (result.code === "200") {
        UKToast.show({
            content: "Added to Cart!", // 弹窗内容
            icon: "success", // 可选：success, fail, loading, warn, info，影响图标
        });
        refreshCb && refreshCb();
    } else {
        UKToast.show({
            content: "Failed to add to Cart!", // 弹窗内容
            icon: "error", // 可选：success, fail, loading, warn, info，影响图标
        });
    }
};

/**
 * 通用加车
 * @param props refer 虚拟加车
 */
export const AddCart = async (props: CartParams = {}) => {
    const { refer, num, skuId, cartuuid } = props
    return await jdiColorFetch("trade_cart_addItem", {
        method: "post",
        bodyQuery: {
            verticalTag: "cn_ybxt_b2c",
            scene: 0, //  1 预售 2 拼团
            operations: {
                products: [{
                    itemType: 1, //  1 单品 4
                    num,
                    skuId
                }]
            },
            cartuuid, // "0cef7cf0-933a-4837-9c7c-602322c28ed7",
            userActionId: "addCart",
            refer
        },
    });
}

/**
 * 商详评论模块
 * @param props refer 虚拟加车
 */
export const getProductCommentFloorInfo = async ({ skuId }: Comstickied=mnentReqParams) => {
    const res = await jdiColorFetch("content_review_getItem", {
        method: "post",
        bodyQuery: {
            skuId
        },
        next: {
          revalidate: 5
        }
    });
    
    if (res.error) {
        return null
    }
    if (res.code === '200') {
        return res?.data || null
    }
    return null
}


/**
 * 商详全部评论
 * @param props refer 虚拟加车
 */
export const getInitCommentListPage = async ({ skuId, type = 0, currentPage = 1, pageSize = 10 }: CommnentReqParams) => {
    const res = await jdiColorFetch("content_review_getList", {
        method: "post",
        bodyQuery: {
            skuId,
            type,
            currentPage,
            pageSize
        }

    });
    return res
}

// 点赞/取消点赞接口
export const handleHelpfulOperation = async ({ commentId, operation }: HelpfulReqParams) => {
    return await jdiColorFetch("content_review_like", {
        method: "post",
        bodyQuery: {
            commentId,
            operation
        },
    });
}


// 举报原因枚举接口
export const getReportReason = async () => {
    const res = await jdiColorFetch("content_review_getReportReasons", {
        method: "get"
    });

    if (res && res.success) {
        return res?.data
    }
    // 处理非0 失败异常情况
    return null
}
// 删除回复
export const deleteReply = async (params: {
    replyId: string,
    skuId: string
}) => {
    return await jdiColorFetch("content_review_deleteReply", {
        method: "post",
        // body: new URLSearchParams({
        //     body: JSON.stringify(params)
        // }),
        bodyQuery: params
    });
}
// 删除回复
export const handleDeleteReply = async ({
    skuId,
    replyId,
}: { skuId: string; replyId: string; }) => {
    return await jdiColorFetch("content_review_deleteReply", {
        method: "post",
        bodyQuery: {
            skuId,
            replyId,
        },
    })
};

// 删除商品评论 rbi.b2c.v1_1.comment.deleteComment
export const handleDeleteComment = async (params: { commentId: string; skuId: string }) => {
    return await jdiColorFetch("content_review_delete", {
        method: "post",
        bodyQuery: params,
    });
};
// 匿名、公开
export const setAnonymousFlag = async (params: {
    commentId: string, skuId: string, anonymous: number
}) => {
    return await jdiColorFetch("content_review_setAnonymous", {
        method: "post",
        bodyQuery: params,
    });
}
// rbi.b2c.v1_1.comment.reportComment
export const reportComment = async (params: ReportCommentReq | any) => {
    const res = await jdiColorFetch("content_review_report", {
        method: "post",
        bodyQuery: params,
    })

    return res
}
// 获取评价详情 rbi.b2c.v1_1.comment.initCommentDetailPage

export const getInitCommentDetailPage = async (commentDetailReq: CommentDetailReq) => {
    const res = await jdiColorFetch("content_review_get", {
        method: "post",
        bodyQuery: {
            ...commentDetailReq, pageSize: 30
        },
    })
    return res
}

// rbi.b2c.v1_1.comment.publishReplyComment 发表回复
// 导出一个异步函数，用于发布回复评论
export const publishReplyComment = async (params: PublishReplyCommentReq) => {
    // 使用 jdiColorFetch 函数发送 POST 请求到 "intl_rbi_comment_publishReplyComment" 接口
    // 并将 params 作为请求体传递
    const res = await jdiColorFetch("content_review_reply", {
        method: "post",
        bodyQuery: params,
    })
    // 返回接口的响应结果
    return res
}
// 平台心智
export const queryPlatMind = async () => {
    // 使用 jdiColorFetch 函数发送 POST 请求到 "intl_rbi_comment_publishReplyComment" 接口
    // 并将 params 作为请求体传递
    const res = await jdiColorFetch("user_mind_get", {
        bodyQuery: {
            mindScope: 9
        },
    })

    if (res.code === "200") {
        // 返回接口的响应结果
        return res?.data?.[0]
    }

}

export const fetchUserReviewDetail = async ({
    commentId,
    parentReplyId,
    skuId,
}: {
    commentId: string;
    parentReplyId: string | number;
    skuId: string;
}): Promise<UserReviewedDetailResponse | null> => {
    return await jdiColorFetch("content_review_get", {
        method: "post",
        bodyQuery: {
            commentId,
            parentReplyId,
            skuId,
            pageSize: 30,
        },
    })
};
