"use client";
import Image, { ImageProps } from "next/image";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import { useState, useEffect, useRef } from "react"
import ErrorImg from "@/assets/icons/errorImg.svg"
import DefaultAvatar from "@/assets/icons/defaultAvatar.svg"
import style from './index.module.scss'

interface props {
  src: string;  //图片路径
  width: number; //图片生成CDN的宽度
  height: number; //图片生成CDN的高度
  quality?: number; //图片清晰度，默认70
  alt?: string; //图片描述
  errorImgType?: number; //展示兜底图的类型，默认展示商品兜底图; -1：不展示兜底图，直接隐藏图片元素；1: 展示头像的兜底图
  className?: string //传入样式名
  onError?: Function //图片加载失败钩子函数
}
interface loaderProps {
  src: string;
  width: number;
  quality?: number;
}

interface ClientProps {
  onError: () => void;
  onLoad: () => void;
}

export default function CDNImage(props: ImageProps | props) {
  const { src, width: originW, height: originH, alt, quality, errorImgType, className, onError: onShowError, ...rest }: any = props;
  const cdnImage = useRef(null)
  const [isLoad, setIsLoad] = useState(false)
  const cdnImageLoader = ({ src, width, quality = 70 }: loaderProps) => {
    const height = Math.round((originH / originW) * width);
    return /^(http|https):\/\/|\/\//.test(src)
      ? src
      : imageUtil.concatImgUrlWithoutForceHttps(
        src,
        width,
        height,
        quality,
        process.env.NEXT_PUBLIC_IMAGE_CDN_DOMAIN?.split(",")?.filter((v) => v.trim()),
      );
  };

  const [clientProps, setClientProps] = useState<ClientProps>();

  const [isError, setIsError] = useState<boolean>(false);

  const onError = () => {
    setIsError(true);
    setIsLoad(true)
    onShowError?.();
  };

  const onLoad = () => {
    setIsLoad(true)
  }
  
  useEffect(() => {
    setClientProps({
      onError,
      onLoad
    });
  }, []);

  // 图片加载失败时处理逻辑
  if (!src || isError) {
    return errorImgType === -1 ? null : 
            errorImgType === 1 ? <DefaultAvatar className={className} {...rest}/> 
            : <ErrorImg className={className} {...rest}/>
  }

  return <Image
    className={`${!isLoad && style.cdnImage} ${className || ''}`}
    ref={cdnImage}
    loader={cdnImageLoader}
    src={src}
    width={originW}
    height={originH}
    alt={alt || ''}
    {...rest}
    {...clientProps}
  />;
}
